import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <Parallax bgImage={about} bgImageAlt="Entree Dish" strength={5}>
          <div style={{ height: '365px' }} />
        </Parallax>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center textCenter mv="50px">
          <CFView
            image={`url(${about}) center / cover no-repeat`}
            style={{ backgroundAttachment: 'fixed' }}
            w="90%"
            alt="About"
            h="420px"
          ></CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}

import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { hero, logo, mobileHero, bg, menu, reserveButton } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={logo} alt="Logo" w="50%" mv="10px" />
        </CFView>
        <Parallax bgImage={hero} bgImageAlt="Entree Dish" strength={50}>
          <div style={{ height: '365px' }} />
        </Parallax>
        <CFView mt="20px" pulsate column center>
          <OrderPickupButton />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" zIndex={90} column center>
          <CFImage src={logo} alt="Logo" w="200px" mv="10px" />
          <CFView
            image={`url(${hero}) 0 20% / cover no-repeat`}
            style={{ backgroundAttachment: 'fixed' }}
            w="90%"
            alt="Hero"
            h="420px"
          ></CFView>
          <CFView row center mv="25px">
            <CFView pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
